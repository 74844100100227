import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import composedDocumentRequireds from "../../../containers/DocumentRequiredsContainer";
import DocumentRequiredList from "./DocumentRequiredList";
import DocumentRequiredListMobile from "./DocumentRequiredListMobile";
// Speed Dial - Start
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TaskAlt from "@mui/icons-material/TaskAlt";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { redirectUrl } from "../../../services/GlobalUrls";
import { Tooltip } from "@mui/material";
const actions = [
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Add Documents" },
  { icon: <TaskAlt />, name: "Update Address" },
];
// Speed Dial - End

const DocumentRequiredContainer = ({ deviceInfo, ...props }) => {
  const { form, loader, userProfile, documentCompliance } = props;
  const [viewToolTip, setViewToolTip] = useState({ update: false, add: false });
  let cndApproved = localStorage.getItem("tempUserStatus");
  let hideButton,
    addUrl,
    urlAddress1,
    urlAddress2,
    urlNational,
    urlTraining,
    urlAdditional;
  documentCompliance.map((compliance, i) => {
    //Button Conditions
    if (compliance.cnd_compliance_doc_master_id == "nationalinsurance") {
      hideButton = compliance.cnd_compliance_doc_master_id;
    }

    //Get Values
    if (compliance.cnd_compliance_doc_master_id == "address1") {
      urlAddress1 = compliance.cnd_compliance_doc_master_id;
    }
    if (compliance.cnd_compliance_doc_master_id == "address2") {
      urlAddress2 = compliance.cnd_compliance_doc_master_id;
    }
    if (compliance.cnd_compliance_doc_master_id == "nationalinsurance") {
      urlNational = compliance.cnd_compliance_doc_master_id;
    }
    if (compliance.cnd_compliance_doc_master_id == "training") {
      urlTraining = compliance.cnd_compliance_doc_master_id;
    }
    if (compliance.cnd_compliance_doc_master_id == "additional") {
      urlAdditional = compliance.cnd_compliance_doc_master_id;
    }
  });

  //Url Conditions
  if (!urlAddress1) {
    addUrl = "/create-document-address1";
  } else if (!urlAddress2) {
    addUrl = "/create-document-address2";
  } else if (!urlNational) {
    addUrl = "/create-document-national-insurance";
  } else if (!urlTraining) {
    addUrl = "/create-document-training-certificate";
  } else if (!urlAdditional) {
    addUrl = "/create-document-additional";
  } else {
    addUrl = "/create-document-additional";
  }

  //addUrl = "/create-document-address1";

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Documents Required
          </h5>
          {/*addUrl*/}
          {deviceInfo && deviceInfo == "Mobile" ? (
            <>
              <Tooltip
                title="Add Documents"
                className="text-sm"
                open={viewToolTip.add}
                placement="top"
              >
                <a
                  href={addUrl}
                  className="pull-right btn btn-success btn-sm"
                  onTouchStart={(e) => {
                    setViewToolTip({ add: true });
                  }}
                  onTouchEnd={(e) => {
                    setViewToolTip({ add: false });
                  }}
                >
                  <i className="fa fa-plus"></i>
                </a>
              </Tooltip>
              {hideButton && cndApproved == "Approved" && (
                <Tooltip
                  title="Update Address"
                  className="text-sm"
                  open={viewToolTip.update}
                  placement="top"
                >
                  <a
                    href="/create-document-update-address"
                    className="pull-right btn btn-primary btn-sm mr-2"
                    onTouchStart={(e) => {
                      setViewToolTip({ update: true });
                    }}
                    onTouchEnd={(e) => {
                      setViewToolTip({ update: false });
                    }}
                  >
                    <i className="fa fa-pencil-square"></i>
                  </a>
                </Tooltip>
              )}

              {/* <Box
                sx={{
                  position: "fixed", // Fixed position for the entire Box
                  bottom: 60,
                  right: 16,
                  height: "auto", // Allow height to adjust based on its content
                  transform: "translateZ(0px)",
                  zIndex: "10",
                  opacity: "1"
                }}
              >
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  sx={{ position: "relative" }} // Relative positioning within the fixed Box
                  icon={<i className="fa fa-plus"></i>}
                  // onClick={() => redirectUrl('/create-right-to-work')}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                    />
                  ))}
                  
                </SpeedDial>
              </Box>                   */}
            </>
          ) : (
            <>
              <a href={addUrl} className="site-button yellow btn-sm pull-right">
                Add Documents
              </a>
              {hideButton && cndApproved == "Approved" && (
                <a
                  href="/create-document-update-address"
                  className="mr-2 site-button green btn-sm pull-right"
                >
                  Update Address
                </a>
              )}
            </>
          )}
        </div>
        {deviceInfo && deviceInfo == "Mobile" ? (
          <DocumentRequiredListMobile />
        ) : (
          <DocumentRequiredList />
        )}
      </div>
    </React.Fragment>
  );
};

export default composedDocumentRequireds(DocumentRequiredContainer);
