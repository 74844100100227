import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import Error from "../../Layout/Error";
import { setError } from "../../../redux/actions/ErrorActions";
import Label from "../../Layout/Label";
import DBSYesForm from "./DBSYesForm";
import DBSNoForm from "./DBSNoForm";
import DBSYesUpperForm from "./DBSYesUpperForm";
import DBSNoUpperForm from "./DBSNoUpperForm";
import { checkDBSValidation } from "../../../helpers/CheckDBSValidation";
import { getBase64 } from "../../../helpers/FileToBase64";
const DBSmainForm = (props) => {
  const params = useParams();
  const { form, errors, loader, dbsdocuments } = props;
  const [cndtype, setFormdata] = useState("yes");
  const [dbFirstFormValues, setDbsFirstFormValue] = useState({});
  const [dbsNoFirstForm, setDbsNoFirstForm] = useState({
    cnd_dbs_apply_type: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputList, setInputList] = useState([]);

  const handleInputChange = async (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (name == "cnd_dbs_certificate_upload_file")
      list[index][name] = await getBase64(e.target.files[0]);
    setInputList(list);
    if (name != "cnd_dbs_type" && name != "cnd_dbs_apply_type")
      validationErrors();
  };

  const onChangeFirstFormValue = (e) => {
    const { value, name } = e.target;
    if (name == "cnd_dbs_type") {
      setInputList([]);
      setDbsNoFirstForm({ cnd_dbs_apply_type: "" });
      setDbsFirstFormValue({});
      let upperFormYes = {};
      let loadForm = [];
      if (value == "yes") {
        if (dbsdocuments.length > 0) {
          dbsdocuments.map((dbs, index) => {
            if (dbs.cnd_dbs_type == "yes") {
              dbFirstFormValues["cnd_dbs_holder_surname"] =
                dbs.cnd_dbs_holder_surname;
              dbFirstFormValues["cnd_dbs_holder_title"] =
                dbs.cnd_dbs_holder_title;
              dbFirstFormValues["cnd_dbs_type"] = dbs.cnd_dbs_type;
              dbFirstFormValues["cnd_dbs_holder_dob"] = dbs?.cnd_dbs_holder_dob ? splitDateToFormat(
                dbs?.cnd_dbs_holder_dob
              ) : "";
            }
          });
        }
      } else if (value == "no") {
        if (dbsdocuments.length > 0) {
          dbsdocuments.map((dbs, index) => {
            if (dbs.cnd_dbs_type == "no") {
              dbFirstFormValues["cnd_dbs_apply_type"] = dbs.cnd_dbs_apply_type;
              dbFirstFormValues["cnd_dbs_holder_dob"] = dbs?.cnd_dbs_holder_dob ? splitDateToFormat(
                dbs?.cnd_dbs_holder_dob
              ) : "";
              dbFirstFormValues["cnd_dbs_holder_title"] =
                dbs.cnd_dbs_holder_title;
              dbFirstFormValues["cnd_dbs_birth_of_town"] =
                dbs.cnd_dbs_birth_of_town;
              dbFirstFormValues["cnd_dbs_country_of_birthtext"] =
                dbs.cnd_dbs_country_of_birthtext;
              dbFirstFormValues["cnd_dbs_nis_no"] = dbs.cnd_dbs_nis_no;
              dbFirstFormValues["cnd_dbs_surname_of_birth"] =
                dbs.cnd_dbs_surname_of_birth;
              dbFirstFormValues["cnd_dbs_type"] = dbs.cnd_dbs_type;
            }
          });
        }
      }
    }
    dbFirstFormValues[name] = value;
    setDbsFirstFormValue(dbFirstFormValues);
    setValuesToAllFormData(dbFirstFormValues);
    //validationErrors();
  };

  const onChangeDBSYesAfterForm = (e) => {
    const { value, name } = e.target;
    let userSelectOptions = { ...dbsNoFirstForm };
    userSelectOptions[name] = value;
    setDbsNoFirstForm(userSelectOptions);
  };

  const setValuesToAllFormData = (data) => {
    inputList.map((formValue, formIndex) => {
      inputList[formIndex].users_access_token =
        props.userAuth.users_access_token;
      inputList[formIndex].cnd_dbs_type = data.cnd_dbs_type;
      inputList[formIndex].cnd_dbs_apply_type = data.cnd_dbs_apply_type;
      inputList[formIndex].cnd_dbs_holder_surname = data.cnd_dbs_holder_surname;
      inputList[formIndex].cnd_dbs_holder_dob = data?.cnd_dbs_holder_dob;
      inputList[formIndex].cnd_dbs_birth_of_town = data.cnd_dbs_birth_of_town;
      inputList[formIndex].cnd_dbs_country_of_birth =
        data.cnd_dbs_country_of_birth;
      inputList[formIndex].cnd_dbs_nis_no = data.cnd_dbs_nis_no;
      inputList[formIndex].cnd_dbs_surname_of_birth =
        data.cnd_dbs_surname_of_birth;
      //Commented on (05-06-2023)
      // inputList[formIndex].cnd_address_address = data.cnd_address_address ? data.cnd_address_address : data.cnd_address;
      // inputList[formIndex].cnd_address_city = data.cnd_address_city ? data.cnd_address_city : data.cnd_dbs_birth_of_town;
      // inputList[formIndex].cnd_address_country = data.cnd_address_country ? data.cnd_address_country : data.cnd_dbs_country_of_birth;
    });
    setInputList(inputList);
    setFormdata(data.cnd_dbs_type);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = async () => {
    let loadForm = {};
    loadForm = {
      users_access_token: props.userAuth.users_access_token,
      cnd_dbs_type: dbFirstFormValues.cnd_dbs_type,
      cnd_dbs_apply_type: dbsNoFirstForm.cnd_dbs_apply_type,
      cnd_dbs_holder_certificate_number: "",
      cnd_dbs_holder_surname: "",
      cnd_dbs_holder_dob: "",
      cnd_dbs_certificate_doc_master_id: "",
      cnd_dbs_certificate_upload_file: "",
      cnd_dbs_certificate_description: "",
      cnd_dbs_certificate_exp_date: "",
      cnd_dbs_certificate_reference: "",
      cnd_address_city: "",
      cnd_address_postcode: "",
      cnd_address_country: "",
      cnd_address_start_date: "",
      cnd_address_end_date: "",
      cnd_address_address: "",
      cnd_dbs_certificate_update_service: "",
      cnd_dbs_birth_of_town: "",
      cnd_dbs_country_of_birth: "",
      cnd_dbs_nis_no: "",
      cnd_dbs_surname_of_birth: "",
    };

    await setInputList([...inputList, loadForm]);
  };

  const validationErrors = () => {
    let validations = checkDBSValidation(inputList, cndtype);
    if (validations.error == true) {
      dispatch(setError(validations.stateData));
      return true;
    } else {
      return false;
    }
  };

  const calculateYear = (startDate, endDate) => {
    // birthday is a date
    return (
      new Date(new Date(startDate) - new Date(endDate)).getFullYear() - 1970
    );
  };

  const validateExperience = () => {
    let totalExperience = 0;
    if (cndtype === "no") {
      inputList.map((list) => {
        totalExperience =
          totalExperience +
          calculateYear(list.cnd_address_start_date, list.cnd_address_end_date);
        console.log(totalExperience);
      });
      if (totalExperience < 5) {
        dispatch(
          setError({
            total_experience_error:
              "Experience Should Not be less than 5 Years",
          })
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let validateExp = validateExperience();
    setValuesToAllFormData(dbFirstFormValues);
    if (!validationErrors()) {
      document.getElementById("loader").style.display = "block";
      dispatch(setError([]));
      dispatch(
        updateDataOnRequest(
          inputList,
          "UpdateDBSDocuments",
          "",
          getDataOnRequest(
            props.userAuth.users_access_token,
            "GetDBSDocuments",
            "SET_DBS_DOCUMENT"
          )
        )
      );
      dispatch(
        getDataOnRequest(
          props.userAuth.users_access_token,
          "ProgressBar",
          "SET_PROGRESS"
        )
      );
      //history.push('/dbs-document')
    }
  };

  useEffect(() => {
    let loadForm = [];
    let upperFormYes = {};
    loadForm = [
      {
        users_access_token: props.userAuth.users_access_token,
        cnd_dbs_type: "yes",
        cnd_dbs_apply_type: "",
        cnd_dbs_holder_certificate_number: "",
        cnd_dbs_holder_surname: "",
        cnd_dbs_holder_dob: "",
        cnd_dbs_certificate_doc_master_id: "",
        cnd_dbs_certificate_upload_file: "",
        cnd_dbs_certificate_description: "",
        cnd_dbs_certificate_update_service: "",
        cnd_dbs_certificate_exp_date: "",
        cnd_dbs_certificate_reference: "",
        cnd_address_city: "",
        cnd_address_postcode: "",
        cnd_address_country: "",
        cnd_address_start_date: "",
        cnd_address_end_date: "",
        cnd_address_address: "",
        cnd_dbs_birth_of_town: "",
        cnd_dbs_country_of_birth: "",
        cnd_dbs_nis_no: "",
        cnd_dbs_surname_of_birth: "",
      },
    ];
    upperFormYes = {
      cnd_dbs_type: "yes",
      cnd_dbs_apply_type: "",
      cnd_dbs_certificate_update_service: "yes",
      cnd_dbs_holder_surname: "",
      cnd_dbs_holder_dob: "",
      cnd_dbs_country_of_birth: "",
      cnd_dbs_nis_no: "",
      cnd_dbs_birth_of_town: "",
      cnd_dbs_surname_of_birth: "",
    };
    if (props.dbsdocument && props.dbsdocument.length > 0) {
      loadForm = props.dbsdocument;
      upperFormYes = {
        cnd_dbs_type: props.dbsdocument[0].cnd_dbs_type,
        cnd_dbs_certificate_update_service:
          props.dbsdocument[0].cnd_dbs_certificate_update_service,
        cnd_dbs_apply_type: props.dbsdocument[0].cnd_dbs_apply_type,
        cnd_dbs_holder_surname: props.dbsdocument[0].cnd_dbs_holder_surname,
        cnd_dbs_holder_dob: props.dbsdocument[0]?.cnd_dbs_holder_dob,
        cnd_dbs_country_of_birth: props.dbsdocument[0].cnd_dbs_country_of_birth,
        cnd_dbs_nis_no: props.dbsdocument[0].cnd_dbs_nis_no,
        cnd_dbs_birth_of_town: props.dbsdocument[0].cnd_dbs_nis_no,
        cnd_dbs_surname_of_birth: props.dbsdocument[0].cnd_dbs_surname_of_birth,
      };
      setDbsNoFirstForm({
        cnd_dbs_apply_type: props.dbsdocument[0].cnd_dbs_apply_type,
      });
      setFormdata(props.dbsdocument[0].cnd_dbs_type);
    }
    if (
      props.dbsdocument.length <= 0 &&
      props.dbsdocuments &&
      props.dbsdocuments.length > 0
    ) {
      /*upperFormYes = {
        cnd_dbs_holder_surname: dbsdocuments[0].users_lastname, //Username Changes Added
        cnd_dbs_holder_dob: splitDateToFormat(dbsdocuments[0].cnd_birthdate), //Birthdate Changes Added
        cnd_dbs_type: dbsdocuments[0].cnd_dbs_type,
      };
      loadForm = [upperFormYes];*/
      if (
        dbsdocuments[0].cnd_dbs_type == "yes" ||
        dbsdocuments[0].cnd_dbs_type == null
      ) {
        upperFormYes = {
          cnd_dbs_holder_surname: dbsdocuments[0].users_lastname, //Username Changes Added
          cnd_dbs_holder_dob: dbsdocuments[0]?.cnd_birthdate ? splitDateToFormat(dbsdocuments[0].cnd_birthdate) : "", //Birthdate Changes Added
          //cnd_dbs_type: dbsdocuments[0].cnd_dbs_type,
          cnd_dbs_type:
            dbsdocuments[0].cnd_dbs_type == null
              ? "yes"
              : dbsdocuments[0].cnd_dbs_type,

          //No->Yes->Form Prefiled Values
          cnd_address_address: dbsdocuments[0].cnd_address, //Address
          cnd_address_city: dbsdocuments[0].cnd_town,
          cnd_address_postcode: dbsdocuments[0].cnd_postcode,
          cnd_address_country: dbsdocuments[0].cnd_country,
          cnd_dbs_surname_of_birth: dbsdocuments[0].users_lastname, //Lastname
          cnd_dbs_birth_of_town: dbsdocuments[0].cnd_town, //Birth Town
          cnd_dbs_nis_no: dbsdocuments[0].cnd_bank_national_insurance, //National Insurance Number Added From Bank Details
          cnd_dbs_country_of_birth: dbsdocuments[0].cnd_country, //Birth Country
        };
        loadForm = [upperFormYes];
      }
      if (dbsdocuments[0].cnd_dbs_type == "no") {
        upperFormYes = {
          cnd_dbs_holder_surname: dbsdocuments[0].users_lastname, //Username Changes Added
          cnd_dbs_holder_dob: dbsdocuments[0]?.cnd_birthdate ? splitDateToFormat(dbsdocuments[0].cnd_birthdate) : "", //
          cnd_dbs_apply_type: dbsdocuments[0].cnd_dbs_apply_type,
          // cnd_dbs_holder_dob: splitDateToFormat(dbsdocuments[0].cnd_dbs_holder_dob),
          cnd_dbs_birth_of_town: dbsdocuments[0].cnd_dbs_birth_of_town,
          cnd_dbs_country_of_birth: dbsdocuments[0].cnd_dbs_country_of_birth,
          cnd_dbs_nis_no: dbsdocuments[0].cnd_bank_national_insurance, //National Insurance Number Added From Bank Details
          cnd_address_city: dbsdocuments[0].cnd_town,
          cnd_address_postcode: dbsdocuments[0].cnd_postcode,
          cnd_address_country: dbsdocuments[0].cnd_country,
          cnd_address_address: dbsdocuments[0].cnd_address, //Address
          cnd_dbs_surname_of_birth: dbsdocuments[0].cnd_dbs_surname_of_birth,
          // cnd_dbs_type: dbsdocuments[0].cnd_dbs_type,
          cnd_dbs_type: cndtype,
        };
        loadForm = [upperFormYes];
      }
    }
    setDbsFirstFormValue(upperFormYes);
    setDbsNoFirstForm(upperFormYes);
    setValuesToAllFormData(upperFormYes);
    setInputList(loadForm);
  }, [props.userAuth, props.dbsdocument, params.id]);

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };
  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <form method="post" encType="multipart/form-data">
          <div className="row m-b30">
            <div className="job-bx-title clearfix">
              <h5 className="font-weight-700 pull-left text-uppercase">
                Agency Worker DBS Disclosure Declaration
              </h5>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <Label labelName="Do You Have DBS Certificate?" />
                <Form.Control
                  as="select"
                  name="cnd_dbs_type"
                  custom
                  onChange={(e) => onChangeFirstFormValue(e)}
                  className="custom-select"
                >
                  <option
                    value="yes"
                    selected={
                      inputList[0] &&
                      inputList[0].cnd_dbs_type == "yes" &&
                      "selected"
                    }
                  >
                    Yes
                  </option>
                  <option
                    value="no"
                    selected={
                      inputList[0] &&
                      inputList[0].cnd_dbs_type == "no" &&
                      "selected"
                    }
                  >
                    No
                  </option>
                </Form.Control>
                <Error errors={errors[0]} fieldName={"cnd_dbs_type"} />
              </div>
            </div>
            {/*<div className="col-lg-6 col-md-6"></div>*/}
            {inputList[0] && inputList[0].cnd_dbs_type == "yes" ? (
              <DBSYesUpperForm
                dbsType={dbFirstFormValues.cnd_dbs_type}
                inputList={inputList}
                onChangeFirstFormValue={(e) => onChangeFirstFormValue(e)}
              />
            ) : (
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <Label labelName="Do you want to apply the DBS certificate with Staffing Connect?" />
                  <Form.Control
                    as="select"
                    name="cnd_dbs_apply_type"
                    custom
                    // defaultValue={dbFirstFormValues.cnd_dbs_apply_type}
                    onChange={(e) => onChangeDBSYesAfterForm(e)}
                    className="custom-select"
                  >
                    <option value="">Please select value</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                  <Error errors={errors[0]} fieldName={"cnd_dbs_type"} />
                </div>
              </div>
            )}
            {dbsNoFirstForm.cnd_dbs_apply_type == "yes" &&
              dbFirstFormValues.cnd_dbs_type == "no" && (
                <DBSNoUpperForm
                  dbsType={dbFirstFormValues.cnd_dbs_type}
                  inputList={inputList}
                  onChangeFirstFormValue={(e) => onChangeFirstFormValue(e)}
                />
              )}
            {inputList.map((x, i) => {
              return (
                <React.Fragment key={i + x.cnd_dbs_type}>
                  {x.cnd_dbs_type == "yes" && (
                    <>
                      <div className="col-lg-12 col-md-12">
                        {inputList.length !== 1 && i > 0 && (
                          <i
                            className="pull-right fa fa-trash red-icon"
                            onClick={(e) => handleRemoveClick(i)}
                          ></i>
                        )}
                        {inputList[0].cnd_dbs_type == "no" && (
                          <h6>Address - {i + 1}</h6>
                        )}
                      </div>
                      <DBSYesForm
                        x={x}
                        i={i}
                        formValue={x}
                        dbsType={dbFirstFormValues.cnd_dbs_type}
                        handleInputChange={(e, i) => handleInputChange(e, i)}
                      />
                    </>
                  )}
                  {dbsNoFirstForm.cnd_dbs_apply_type == "yes" &&
                    x.cnd_dbs_type == "no" && (
                      <>
                        <div className="col-lg-12 col-md-12">
                          {inputList.length !== 1 && i > 0 && (
                            <i
                              className="pull-right fa fa-trash red-icon"
                              onClick={(e) => handleRemoveClick(i)}
                            ></i>
                          )}
                          <h6>Address - {i + 1}</h6>
                        </div>
                        <DBSNoForm
                          x={x}
                          i={i}
                          formValue={x}
                          dbsType={dbFirstFormValues.cnd_dbs_type}
                          handleInputChange={(e, i) => handleInputChange(e, i)}
                        />
                      </>
                    )}
                  {(dbsNoFirstForm.cnd_dbs_apply_type == "no" ||
                    !dbsNoFirstForm.cnd_dbs_apply_type) &&
                  inputList[0].cnd_dbs_type == "no" &&
                  i === 0 ? (
                    <>
                      {dbsNoFirstForm.cnd_dbs_apply_type == "no" && (
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <Label
                              labelName="Please connect to Staffing team for DBS related query."
                              mandetory={false}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="col-lg-12 col-md-12 mt-2">
                        {dbFirstFormValues.cnd_dbs_type &&
                          inputList.length - 1 === i && (
                            <Button
                              disabled={loader ? "disabled" : ""}
                              className="site-button m-b30 mr-2 pull-right"
                              onClick={(e) => onSubmit(e)}
                            >
                              {loader && (
                                <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"
                                />
                              )}
                              {!loader ? "Save" : "Loading..."}
                            </Button>
                          )}
                        {inputList.length - 1 === i && (
                          // <Link to={"/identity-document"}  className="site-button m-b30 m-l15" >Next</Link>
                          <a
                            href={"/dbs-document"}
                            className="site-button yellow m-b30 mr-2 pull-right"
                          >
                            Back
                          </a>
                        )}
                        {dbFirstFormValues.cnd_dbs_type &&
                          dbFirstFormValues.cnd_dbs_type == "no" &&
                          inputList.length - 1 === i && (
                            <button
                              className="site-button purple m-b30 mr-2 pull-right"
                              onClick={(e) => handleAddClick(i)}
                            >
                              Add More
                            </button>
                          )}
                      </div>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  //console.log(state);
  return {
    dbsdocument: state.dbsdocument.dbsdocument,
    dbsdocuments: state.dbsdocuments.dbsdocuments,
    userAuth: state.auth.authUserData,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps)(DBSmainForm);
