import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Col, Form, Modal, Tab, Tabs } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import constants from "../../../constants";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { formInputFieldsSign, validationSchemaSign } from "./SignDocHelper";
import LoadFormFields from "../../Layout/LoadFormFields";
import composedIdentityDocumentsContainer from "../../../containers/IdentityDocumentsContainer";
import pdfTemp from "../../../docs/Employment-Contract.pdf";
import { redirectUrl } from "../../../services/GlobalUrls";
import Input from "../../FormElements/Input";
import { jobTitle } from "../../../helpers/WorkRefrencesHelper";

import Multiselect from "multiselect-react-dropdown";
import { capitalizeWords } from "../../../services/GlobalStringOp";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { convertToSnakeCase } from "../../../services/StringOperation";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const IdentityContainer = ({ deviceInfo, ...props }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [msgShow, setMessageShow] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { progress, userData } = props;
  const [holidayModal, setHolidayModal] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const history = useHistory();
  const [viewToolTip, setViewToolTip] = useState(false);
  // Modal States - Start
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  // Modal State - End
  const [workPreferencesData, setFormdata] = useState({});
  // const [selectedJobTitles, setSelectedJobTitle] = useState([]);
  const [fltMenu, setFltMenu] = useState(false);
  const [driverMenu, setDriverMenu] = useState(false);
  const [selectedJobTitles, setSelectedJobTitle] = useState("");
  const [allJobTitles, setAllJobTitles] = useState([]);
  const [tableColMode, setTableColMode] = useState(true);
  const [payRateOption, setPayRateOption] = useState("");
  const [signRenewDate, setSignRenewDate] = useState(true);

  // Table Pagination And Drop Start
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [showContractPreventMsg, setShowContractPreventMsg] = useState("");
  const [filter, setFilter] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);
  // Table Pagination And Drop End

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaSign),
    mode: "onSubmit",
    reValidateMode: "submit",
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onSubmitSign = (data) => {
    document.getElementById("loader").style.display = "block";
    let formData = data;
    formData["users_access_token"] = props.userAuth.users_access_token;
    formData["user_pay_rate_option"] = payRateOption;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "UpdateUserSign",
        "",
        getDataOnRequest(
          props.userAuth.users_access_token,
          "ClientProfileGet",
          "SET_USER_DATA"
        )
      )
    );
    setShow(false);
    setPageNumber(1);
  };

  const checkFormProfileCompletion = () => {
    let totalProgress = 0;

    if (progress.progress_bar_profile_1 == "1") {
      totalProgress = totalProgress + 10;
    }else{
      setShowContractPreventMsg('My Profile');
    }
    // Flt Menu
    if (fltMenu == true) {
      if (progress.cnd_flt_licenses_progress_bar == "1") {
        totalProgress = totalProgress + 5;
      }else{
        if(!showContractPreventMsg){
          setShowContractPreventMsg('FLT Licence');
        }
      }
    }
    // Driver Menu
    if (driverMenu == true) {
      if (progress.cnd_driver_details_progress_bar == "1") {
        totalProgress = totalProgress + 5;
      }else{
        if(!showContractPreventMsg){
          setShowContractPreventMsg('Driver Details');
        }
      }
    }
    if (progress.progress_bar_reference_2 == "1") {
      if (fltMenu == true) {
        totalProgress = totalProgress + 5;
      } else {
        totalProgress = totalProgress + 10;
      }
    }else{
      if(!showContractPreventMsg){
        setShowContractPreventMsg('Employment & Professional Reference');
      }
    }
    // if (progress.cnd_types_of_services !== "") {
    //   totalProgress = totalProgress + 10;
    // }
    if (progress.progress_bar_agreement_3 == "1") {
      totalProgress = totalProgress + 10;
    }else{
      if(!showContractPreventMsg){
        setShowContractPreventMsg('Agreements');
      }      
    }
    if (progress.progress_bar_bank_4 == "1") {
      if (driverMenu == true) {
        totalProgress = totalProgress + 5;
      } else {
        totalProgress = totalProgress + 10;
      }
    }else{
      if(!showContractPreventMsg){
        setShowContractPreventMsg('Bank Details');
      }
    }
    if (progress.progress_bar_right_to_work_5 == "1") {
      totalProgress = totalProgress + 10;
    }else{
      if(!showContractPreventMsg){
        setShowContractPreventMsg('Right to Work');
      }
    }
    if (progress.progress_bar_work_preference_6 == "1") {
      totalProgress = totalProgress + 10;
    }else{
      if(!showContractPreventMsg){
        setShowContractPreventMsg('Work Preferences');
      }      
    }
    if (progress.progress_bar_compliance_7 == "1") {
      totalProgress = totalProgress + 10;
    }else{
      if(!showContractPreventMsg){
        setShowContractPreventMsg('Documents Required');
      }
    }
    if (progress.dbsDetailsParam == "true") {
      totalProgress = totalProgress + 15;
    }else{
      if(!showContractPreventMsg){
        setShowContractPreventMsg('DBS Certificate');
      }
    }
    if (progress.progress_bar_identity_9 == "1") {
      totalProgress = totalProgress + 15;
    }else{
      if(!showContractPreventMsg){
        setShowContractPreventMsg('Identity Documents');
      }
    }

    if (totalProgress >= 100) {
      setShow(true);
    } else {
      setMessageShow(true);
    }
  };

  const onChangeInputvalue = (e) => {
    let submitCommonButtonDisabled =
      document.getElementById("submitCommonButton");
    let user_term_condition = document.getElementById("user_term_condition");
    let user_sign_signature = document.getElementById("user_sign_signature");
    // console.log("Demo Test :=>"+user_sign_signature.value);
    if (user_sign_signature.value && user_term_condition.checked == true) {
      submitCommonButtonDisabled.disabled = false;
    } else {
      submitCommonButtonDisabled.disabled = true;
    }
    // const { name, value } = e.target;
    // let holidayDetail = {...{a}
    // holidayDetail[name] = value;
    // setFormdata(holidayDetail);
  };

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };

  // Tabs Settings
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  // Modal Setttings
  const changeModalValue = (modalSetValue) => {
    setHolidayModal(modalSetValue);
  };

  // Switch Mode Change Start
  const changeSwitchMode = (callId) => {
    // let contractDetails = document.getElementById("contractDetails");
    if (callId == "rolled_up_pay") {
      setTableColMode(true);
      setPayRateOption(callId);
      // contractDetails.style.display = "block";
    } else {
      setTableColMode(false);
      setPayRateOption(callId);
      // contractDetails.style.display = "none";
    }
    // onSelect("cnd_work_job_title");
  };
  // Switch Model Change End

  // Get Sign Renewed Status - Start
  const getSignStatus = (getDate) => {
    let currentDate = new Date(new Date().toISOString().split("T")[0]);
    let signDate = new Date(String(getDate));

    if (currentDate > signDate) {
      setSignRenewDate(false);
      console.log("Renewed Date Is :=>" + signDate);
    }
  };
  // Get Sign Renewed Status - End
  const convertJobTitleValuesInArray = (valuesInArray) => {
    let mainObj = [];
    let arrayValues = {};
    jobTitle.map((val, i) => {
      arrayValues[val.id] = val.name;
    });
    valuesInArray.map((value, key) => {
      mainObj[key] = { name: arrayValues[value], id: value };
    });
    setSelectedJobTitle(mainObj);
  };

  let scrollFocusElement;
  useEffect(() => {
    // console.log("Work Reference Title :=> "+JSON.stringify(progress.cnd_work_job_title));
    if (progress.cnd_work_job_title) {
      var valuesInArrayJobTitle = progress.cnd_work_job_title.split(",");
      convertJobTitleValuesInArray(valuesInArrayJobTitle, "jobTitle");
    }

    let jobTitleSortObject = Object.values(selectedJobTitles);
    let flt_menu, driver_menu;
    if (jobTitleSortObject) {
      jobTitleSortObject.map((item, index) => {
        // FLT License
        if (item.id == "forklift_truck_drivers_(all_categories)") {
          flt_menu = true;
        }
        // Driver License
        if (
          item.id ==
            convertToSnakeCase("HGV / 7.5 Tonne / Multi-Drop Drivers") ||
          item.id == convertToSnakeCase("HGV & Commercial Vehicle Technicians")
        ) {
          driver_menu = true;
        }
      });
    }
    setFltMenu(flt_menu);
    setDriverMenu(driver_menu);

    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      // window.scrollTo(0, 900);
    }
  }, [scrollFocusElement, progress]);
  // UseEffect For Scroll Stop

  // useEffect(() => {
  //   changeSwitchMode(userData.user_pay_rate_option);
  //   if (userData.user_signature_renewal_date) {
  //     getSignStatus(userData.user_signature_renewal_date);
  //   }
  //   let workDatails = Object.values(props.workPrefrences);
  //   setAllJobTitles(workDatails);
  // }, [props.workPrefrences, props.userData]);
  // console.log("Date Is :=>"+payRateOption);

  // Table Pagination - Start
  useEffect(() => {
    changeSwitchMode(
      userData.user_pay_rate_option
        ? userData.user_pay_rate_option
        : "rolled_up_pay"
    );
    if (userData.user_signature_renewal_date) {
      getSignStatus(userData.user_signature_renewal_date);
    }
    const lenOfData = props.workPrefrences.length;
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);

    let workDatails = Object.values(props.workPrefrences);
    setAllJobTitles(workDatails);
  }, [props.workPrefrences, props.userData]);
  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  // Table Pagination - End

  // Modal Function - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 ">
            {userData &&
            userData.user_sign_signature &&
            userData.user_signed_contract ? (
              <>
                <text className="text-uppercase mb-0">
                  {"Contract Signed ✅"}
                </text>{" "}
                <>
                  {deviceInfo && deviceInfo == "Mobile" ? (
                    <>
                      <Tooltip
                        title="Signed Contract"
                        className="text-sm"
                        open={viewToolTip}
                        placement="top"
                      >
                        <a
                          onClick={() => {
                            if (
                              userData.user_signed_contract_path &&
                              userData.user_signed_contract
                            ) {
                              viewFileModal(
                                constants.IMAGE_URL +
                                  userData.user_signed_contract_path +
                                  userData.user_signed_contract
                              );
                            } else {
                              Swal.fire({
                                title: "Signed Contract Not Available",
                                text: "",
                                icon: "warning",
                                showCancelButton: false,
                                confirmButtonText: "Ok",
                                // confirmButtonColor: 'blue'
                              });
                            }
                          }}
                          className="pull-right btn btn-danger text-white"
                          onTouchStart={(e) => {
                            setViewToolTip(true);
                          }}
                          onTouchEnd={(e) => {
                            setViewToolTip(false);
                          }}
                        >
                          <i className="fa fa-file-pdf-o"></i>
                        </a>
                      </Tooltip>
                    </>
                  ) : (
                    <Button
                      className="btn btn-primary text-white float-right"
                      onClick={() => {
                        if (
                          userData.user_signed_contract_path &&
                          userData.user_signed_contract
                        ) {
                          viewFileModal(
                            constants.IMAGE_URL +
                              userData.user_signed_contract_path +
                              userData.user_signed_contract
                          );
                        } else {
                          Swal.fire({
                            title: "Signed Contract Not Available",
                            text: "",
                            icon: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                            // confirmButtonColor: 'blue'
                          });
                        }
                      }}
                    >
                      <i
                        class="fa fa-file-pdf-o mr-1 text-black bg-white"
                        aria-hidden="true"
                      ></i>{" "}
                      Signed Contract
                    </Button>
                  )}
                </>
              </>
            ) : (
              <>
                <text className="text-uppercase mb-0">{"Sign Contract"}</text>
              </>
            )}
          </h5>
        </div>

        {/* Pay & Charge Start */}

        {userData &&
        userData.user_sign_signature &&
        (userData.user_pay_rate_option == "" ||
          !userData.user_pay_rate_option ||
          userData.user_pay_rate_option == null)
          ? ""
          : (((userData.user_sign_signature == "" ||
              !userData.user_sign_signature ||
              userData.user_sign_signature == null) &&
              (userData.user_pay_rate_option == "" ||
                !userData.user_pay_rate_option ||
                userData.user_pay_rate_option == null)) ||
              ((userData.user_sign_signature == "" ||
                !userData.user_sign_signature ||
                userData.user_sign_signature == null) &&
                userData.user_pay_rate_option != "") ||
              (userData.user_sign_signature &&
                userData.user_pay_rate_option != "")) && (
              <>
                <div
                  class="alert alert-info text-dark alert-dismissible fade show clearfix"
                  role="alert"
                  style={{ borderRadius: "20px" }}
                >
                  <i
                    className="fa fa-info-circle text-dark"
                    aria-hidden="true"
                  ></i>{" "}
                  <strong>
                    This agreement constitutes a zero-hour agency contract
                    between you and Staffing Connect. By entering into this
                    contract, you acknowledge that pay rates for each job title
                    may vary depending on the client and their location. Please
                    consult your recruitment consultant for the specific pay
                    rate applicable to each assignment.
                  </strong>
                </div>

                <div
                  className="job-bx submit-resume mb-5 border"
                  style={{ display: "block" }}
                >
                  <div
                    class="alert alert-warning text-dark alert-dismissible fade show clearfix"
                    role="alert"
                    style={{ borderRadius: "20px" }}
                  >
                    <h6 className="mb-0">
                      {tableColMode == true
                        ? "Rolled Up Pay Contract"
                        : "Non Rolled Up Pay Contract"}
                    </h6>

                    <strong style={{ fontSize: "90%" }}>
                      {tableColMode == true
                        ? "Below are the estimated pay rates for each job title. Please note that these rates are subject to change based on the client and location. The listed pay rate includes both your weekly wages and holiday pay, which will be reflected in your payslip. Please be aware that you will not be eligible to request paid holidays separately, as holiday pay is already incorporated into your pay rate as specified in the table."
                        : "Holiday requests must be submitted with a minimum of two weeks' notice and can only be requested for days when you are not scheduled to work. No holiday requests will be accepted for weekends; only weekdays are permissible. All holiday requests must be submitted in hours, with a maximum of 8 hours per day. Holiday pay will be based on entitlement and cannot be paid in advance. Bank holidays are included within holiday entitlement."}
                    </strong>
                  </div>

                  <form noValidate>
                    <div className="row mb-0">
                      {/* Switch Start */}

                      <center>
                        {/* <div class="form-check form-switch">
                  <Form.Group as={Col} md={12} className="">
                    <Form.Check
                      inline
                      label={<p className="mt-2 mb-0">Rolled Up Pay</p>}
                      onChange={() => changeSwitchMode("rolled_up_pay")}
                      type="checkbox"
                      name="rolled_up_pay"
                      id="rolled_up_pay"
                      role="switch"
                    />

                    <Form.Check
                      inline
                      label={<p className="mt-2 mb-0">Non Rolled Up Pay</p>}
                      onChange={() => changeSwitchMode("non_rolled_up_pay")}
                      type="checkbox"
                      name="non_rolled_up_pay"
                      id="non_rolled_up_pay"
                      role="switch"
                    />
                  </Form.Group>
                </div> */}

                        {/* Switch End */}
                        {/* Table Start */}
                        {allJobTitles && allJobTitles.length > 0 ? (
                          <>
                            <div className="row m-b30" id="scrollFocusElement">
                              {/* {JSON.stringify(allJobTitles)} */}
                              <div className="col-lg-12 col-lg-12 m-b0 wrapper">
                                <h5 className="text-uppercase text-left">{}</h5>
                                <table className="table table-striped table-hover  ">
                                  <thead className="thead-dark">
                                    <tr>
                                      {/*<th className="col-1 text-center" scope="col">Sr. No</th>*/}
                                      <th
                                        className="col-3 text-center"
                                        scope="col"
                                      >
                                        Job Title
                                      </th>
                                      <th
                                        className="col-3 text-center"
                                        scope="col"
                                      >
                                        Pay Rate
                                      </th>
                                      {tableColMode == true && (
                                        <th
                                          className="col-3 text-center"
                                          scope="col"
                                        >
                                          Holiday Pay
                                        </th>
                                      )}
                                      <th
                                        className="col-3 text-center"
                                        scope="col"
                                      >
                                        Total Pay Rate
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody id="myTable">
                                    {allJobTitles &&
                                      allJobTitles
                                        .slice(startIndex, endIndex)
                                        .map((work, i) => (
                                          <tr key={i}>
                                            <td className="col-3 text-center">
                                              {
                                                work.cnd_jobs_and_assignment_title_label
                                              }
                                            </td>
                                            <td className="col-3 text-center">
                                              {work.pay_and_charge_pay_rate
                                                ? "£" +
                                                  work.pay_and_charge_pay_rate
                                                : ""}
                                            </td>
                                            {tableColMode == true && (
                                              <td className="col-3 text-center">
                                                {work.pay_and_charge_holiday_pay
                                                  ? "£" +
                                                    work.pay_and_charge_holiday_pay
                                                  : ""}
                                              </td>
                                            )}
                                            <td className="col-3 text-center">
                                              {tableColMode == false
                                                ? work.pay_and_charge_pay_rate
                                                  ? "£" +
                                                    work.pay_and_charge_pay_rate
                                                  : ""
                                                : work.pay_and_charge_pay_rate_with_holiday
                                                ? "£" +
                                                  work.pay_and_charge_pay_rate_with_holiday
                                                : ""}
                                            </td>
                                          </tr>
                                        ))}
                                  </tbody>
                                  <tfoot
                                    className="mb-0"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <tr>
                                      <td
                                        colSpan={tableColMode == true ? 4 : 3}
                                      >
                                        <Tooltip
                                          className="pull-right"
                                          title={
                                            tableColMode == true
                                              ? "Switch to Non Rolled Up Pay"
                                              : "Switch to Rolled Up Pay"
                                          }
                                        >
                                          <Switch
                                            className="pull-right"
                                            checked={tableColMode}
                                            onChange={(
                                              event,
                                              newInputValue
                                            ) => {
                                              if (tableColMode == true) {
                                                changeSwitchMode(
                                                  "non_rolled_up_pay"
                                                );
                                              } else {
                                                changeSwitchMode(
                                                  "rolled_up_pay"
                                                );
                                              }
                                            }}
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />{" "}
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                                {allJobTitles && allJobTitles.length > 0 && (
                                  <TableDropdownAndPagination
                                    handleDropDown={handleDropDown}
                                    recordLen={recordLen}
                                    handleLeft={handleLeft}
                                    handleRight={handleRight}
                                    renderPageNumbers={renderPageNumbers}
                                    isDropdown={true}
                                    isPaggination={true}
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="container">
                              <div className="row">
                                <div className="col-sm-12 text-center">
                                  <center>
                                    <p>
                                      <h3
                                        style={{ fontSize: "100px" }}
                                        className="text-center text-primary"
                                      >
                                        <i class="fa fa-spinner fa-pulse"></i>
                                      </h3>
                                      <h6 className="text-center">
                                        Please wait
                                      </h6>
                                      {/* <Skeleton className="mb-1" variant="rectangular" width={"100%"} height={20} style={{borderRadius: "20px"}} /> */}
                                    </p>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </center>

                      {/* Table End */}
                    </div>
                  </form>
                </div>
              </>
            )}

        {/* Pat & Charge End */}

        <div className="job-bx submit-resume mb-5 border" id="contractDetails">
          <div className="job-bx-title clearfix">
            <h6 className="font-weight-700 pull-left text-uppercase">
              Read Contract
            </h6>
          </div>
          <form
            onSubmit={handleSubmit(onSubmitSign)}
            style={{ width: "100%", height: "500px" }}
            onChange={onChangeInputvalue}
          >
            {/* <Document
              className="custom-canvas"
              file={pdfTemp}
              width={"100%"}
              height={"1200px"}
              onLoadError={console.error}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {
                Array.apply(null, Array(numPages))
                .map((x, i)=>i+1)
                .map(page => <Page width="800" height="2000" pageNumber={page} /> )
              }
            </Document> */}
            {/* {JSON.stringify(userData.user_signed_contract)} */}

            {userData &&
            userData.user_sign_signature &&
            userData.user_signed_contract ? (
              <iframe
                src={
                  constants.IMAGE_URL +
                  "uploads/" +
                  (userData.user_sign_signature &&
                  (userData.user_pay_rate_option == "" ||
                    !userData.user_pay_rate_option ||
                    userData.user_pay_rate_option == null)
                    ? "Employment-Contract"
                    : payRateOption) +
                  ".pdf" +
                  "#toolbar=0&navpanes=0&scrollbar=0"
                }
                frameborder="1"
                style={{ border: "solid 1px black" }}
                height="500px"
                width="100%"
                toolbar="0"
                id="iframe"
              ></iframe>
            ) : (
              <iframe
                src={
                  constants.IMAGE_URL +
                  "uploads/" +
                  (userData.user_sign_signature &&
                  (userData.user_pay_rate_option == "" ||
                    !userData.user_pay_rate_option ||
                    userData.user_pay_rate_option == null)
                    ? "Employment-Contract"
                    : payRateOption) +
                  ".pdf"
                }
                frameborder="1"
                style={{ border: "solid 1px black" }}
                height="500px"
                width="100%"
                toolbar="0"
                id="iframe"
              ></iframe>
            )}
          </form>
          <Button
            to={"#"}
            disabled={userData.user_sign_signature ? true : false}
            onClick={() => checkFormProfileCompletion()}
            // onClick={() => changeModalValue(true)}
            className="site-button green"
            style={{ marginTop: "5%" }}
          >
            Sign your contract
          </Button>
        </div>
        <div id="scrollFocusElement"></div>
        {userData.user_sign_signature && (
          <>
            <div className="container mt-4 mb-5">
              <div className="row">
                <div className="col-sm-6">
                  <h6>Candidate Signature: </h6>
                </div>
                <div
                  className="col-sm-6 p-2"
                  style={{ border: "solid 1px black" }}
                >
                  <img
                    src={
                      constants.IMAGE_URL +
                      userData.user_sign_store_path +
                      userData.user_sign_signature
                    }
                    style={{ width: "100%", height: "100px" }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {userData.user_sign_signature && (
          <a href={"/dashboard"} className="site-button  m-b15 listingnextbtn">
            Next
          </a>
        )}
        {userData.user_sign_signature && (
          <p>
            {" "}
            You can not Sign the contract again as you have already signed the
            contract on —{" "}
            <span style={{ fontWeight: 600 }}>
              {userData.user_signed_create_date}{" "}
            </span>{" "}
            Address —{" "}
            <span style={{ fontWeight: 600 }}>
              {userData.user_system_id_address}{" "}
            </span>
          </p>
        )}
      </div>
      {/* onHide={() => setShow(false)}  */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        onEnter={onDocumentLoadSuccess}
        size="lg"
      >
        <Modal.Header closeButton>
          <h4 className="text-white mb-0">Sign Document</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="job-bx submit-resume">
            <form
              onSubmit={handleSubmit(onSubmitSign)}
              onChange={onChangeInputvalue}
            >
              <Document
                // className="custom-canvas"
                file={pdfTemp}
                width={"800"}
                height={"800"}
                onLoadError={console.error}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {/* <iframe
                src={
                  "https://docs.google.com/viewer?url=https://api.itstaffingsolutions.co.uk/uploads/Employment-Contract.pdf&embedded=true"
                }
                frameborder="1"
                height="500px"
                width="100%"
              ></iframe> */}

                <iframe
                  src={
                    constants.IMAGE_URL +
                    "uploads/" +
                    (userData.user_sign_signature &&
                    (userData.user_pay_rate_option == "" ||
                      !userData.user_pay_rate_option ||
                      userData.user_pay_rate_option == null)
                      ? "Employment-Contract"
                      : payRateOption) +
                    ".pdf"
                  }
                  frameborder="1"
                  height="500px"
                  width="100%"
                  toolbar="0"
                ></iframe>
              </Document>
              <hr />
              <LoadFormFields
                register={register}
                errors={errors}
                formInputFields={formInputFieldsSign()}
                onChangeInputvalue={(e) => onChangeInputvalue(e)}
                reset={false}
                control={control}
                data={{}}
                backUrl={"/sign-contract"}
              />

              {/* <Label labelName={"Sign The Contract"} />
                <Controller
                  name="user_sign_signature"
                  control={control && control}
                  render={({ field }) => (
                    <SignatureCanvas
                      ref={sigCanvas}
                      onEnd={(e) => field.onChange(formatIntoPng())}
                      penColor="black"
                      canvasProps={{
                        width: 400,
                        height: 200,
                        className: errors["user_sign_signature"] ? "is-invalid" : "",
                        style: { border: "1px solid green" },
                      }}
                    />
                  )}
                /> */}
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={msgShow} onHide={() => setMessageShow(false)} size="l">
        <Modal.Header closeButton>
          <Modal.Title>Sign Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="job-bx submit-resume">
            <h6>Please complete {showContractPreventMsg ? showContractPreventMsg+" section" : "your profile"} to sign the contract.</h6>
          </div>
        </Modal.Body>
      </Modal>

      {fileUrl && (
        <>
          <GlobalViewFileModal
            modalOption={modalOption}
            closeFileModal={closeFileModal}
            fileUrl={fileUrl}
            loaderEnd={loaderEnd}
            modalHeight={modalHeight}
            callModal={callModal}
            title={"Signed Contract"}
            // fileName={fileName}
          />
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    progress: state.progress.progress,
    workPrefrences: state.workPrefrences.workPrefrences,
    workPrefrencesJobTitles:
      state.workPrefrencesJobTitles.workPrefrencesJobTitles,
    userData: state.userProfile.userData,
    userAuth: state.auth.authUserData,
  };
};

export default connect(mapStateToProps)(
  composedIdentityDocumentsContainer(IdentityContainer)
);
