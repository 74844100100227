import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  dbsCertificates,
  dbsDocumentName,
} from "../../../helpers/DocumentsList";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { redirectUrl } from "../../../services/GlobalUrls";
const DBSTable = (props) => {
  const [dbsdocuments, SetDbsdocuments] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");

  const [inputValue, setInputValue] = useState("");

  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.dbsdocuments.length;
    // console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);

    if (props.dbsdocuments) {
      // Filter the documents based on the given conditions
      const storeArray = props.dbsdocuments.filter(
        (docs) =>
          docs.cnd_dbs_certificate_status !== "Deleted" &&
          (docs.cnd_dbs_type === "yes" || docs.cnd_dbs_type === "Yes")
      );

      // Update the state with the filtered documents
      if (storeArray.length > 0) {
        SetDbsdocuments(storeArray);
      }
    }
  }, [props.dbsdocuments, currentPage, recordsPerPage]); // Ensure you include all relevant dependencies

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };

  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  const matchesSearchText = (dbsdocuments) => {
    const searchText = inputValue.toLowerCase();
    return (
      dbsdocuments?.cnd_dbs_certificate_created_date
        ?.toLowerCase()
        .includes(searchText) ||
      dbsCertificates[dbsdocuments?.cnd_dbs_certificate_doc_master_id]
        ?.toLowerCase()
        .includes(searchText) ||
      dbsdocuments?.cnd_dbs_certificate_number
        ?.toLowerCase()
        .includes(searchText) ||
      dbsdocuments?.cnd_dbs_certificate_exp_date
        ?.toLowerCase()
        .includes(searchText)
    );
  };
  // Filter applied jobs based on the search text
  const filterRows = dbsdocuments ? dbsdocuments.filter(matchesSearchText) : [];

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        {/* Search Start */}
        <div className="container">
          <div className="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  onInput={(event, value) => {
                    setInputValue(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Search End */}
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          {filterRows &&
            filterRows.length > 0 &&
            filterRows.slice(startIndex, endIndex).map((dbsdoc, i) => (
              <ul
                className="nav navbar-nav border border-grey p-4 mb-2"
                style={{ borderRadius: "20px" }}
                key={i}
              >
                <li className="text-dark">
                  <i className="fa fa-calendar-o mr-1 text-primary"></i>{" "}
                  {dbsdoc.cnd_dbs_certificate_created_date}
                </li>
                <li className="text-dark">
                  <i className="fa fa-file-text mr-1 text-primary"></i>{" "}
                  <a
                    onClick={() =>
                      viewFileModal(
                        constants.IMAGE_URL +
                          dbsdoc.cnd_dbs_certificate_folder_path +
                          dbsdoc.cnd_dbs_certificate_upload_file,
                        dbsCertificates[
                          dbsdoc.cnd_dbs_certificate_doc_master_id
                        ]
                      )
                    }
                    className={
                      "text-" +
                      (dbsdoc.cnd_dbs_certificate_upload_file.endsWith(
                        ".png"
                      ) ||
                      dbsdoc.cnd_dbs_certificate_upload_file.endsWith(".jpg") ||
                      dbsdoc.cnd_dbs_certificate_upload_file.endsWith(
                        ".jpeg"
                      ) ||
                      dbsdoc.cnd_dbs_certificate_upload_file.endsWith(".pdf")
                        ? "primary"
                        : "primary") +
                      ""
                    }
                  >
                    <u>
                      {dbsdoc.cnd_dbs_certificate_doc_master_id
                        ? dbsCertificates[
                            dbsdoc.cnd_dbs_certificate_doc_master_id
                          ] &&
                          String(
                            dbsCertificates[
                              dbsdoc.cnd_dbs_certificate_doc_master_id
                            ]
                          ).length <= 20
                          ? dbsCertificates[
                              dbsdoc.cnd_dbs_certificate_doc_master_id
                            ]
                          : String(
                              dbsCertificates[
                                dbsdoc.cnd_dbs_certificate_doc_master_id
                              ]
                            ).slice(0, 20) + "..."
                        : ""}
                    </u>
                  </a>
                  <a
                    onClick={() =>
                      viewFileModal(
                        constants.IMAGE_URL +
                          dbsdoc.cnd_dbs_certificate_folder_path +
                          dbsdoc.cnd_dbs_certificate_upload_file,
                        dbsCertificates[
                          dbsdoc.cnd_dbs_certificate_doc_master_id
                        ]
                      )
                    }
                    className={
                      "text-" +
                      (dbsdoc.cnd_dbs_certificate_upload_file.endsWith(
                        ".png"
                      ) ||
                      dbsdoc.cnd_dbs_certificate_upload_file.endsWith(".jpg") ||
                      dbsdoc.cnd_dbs_certificate_upload_file.endsWith(
                        ".jpeg"
                      ) ||
                      dbsdoc.cnd_dbs_certificate_upload_file.endsWith(".pdf")
                        ? "primary"
                        : "primary") +
                      " text-sm pull-right mt--2"
                    }
                  >
                    {/* <i
                          className={
                            "fa fa-" +
                            (dbsdoc.cnd_dbs_certificate_upload_file.endsWith(".png") ||
                            dbsdoc.cnd_dbs_certificate_upload_file.endsWith(".jpg") ||
                            dbsdoc.cnd_dbs_certificate_upload_file.endsWith(".jpeg") ||
                            dbsdoc.cnd_dbs_certificate_upload_file.endsWith(".pdf")
                              ? "eye" //"eye"
                              : "download")
                          }
                        ></i> */}
                    <u>View File</u>
                  </a>
                </li>
                <li className="text-dark">
                  <i className="fa fa-certificate mr-1 text-primary"></i>{" "}
                  {dbsdoc.cnd_dbs_certificate_number &&
                  dbsdoc.cnd_dbs_certificate_number.length <= 15
                    ? dbsdoc.cnd_dbs_certificate_number
                    : dbsdoc.cnd_dbs_certificate_number &&
                      dbsdoc.cnd_dbs_certificate_number.slice(0, 15) + "..."}
                </li>
                <li className="text-dark">
                  <i className="fa fa-calendar-times-o mr-1 text-primary"></i>{" "}
                  {dbsdoc.cnd_dbs_certificate_exp_date}
                </li>
                <li
                  className={
                    new Date() <=
                      new Date(
                        splitDateToFormat(dbsdoc.cnd_dbs_certificate_exp_date)
                      ) &&
                    dateDiffrence(dbsdoc.cnd_dbs_certificate_exp_date) > 30
                      ? "text-success "
                      : new Date() <=
                          new Date(
                            splitDateToFormat(
                              dbsdoc.cnd_dbs_certificate_exp_date
                            )
                          ) &&
                        dateDiffrence(dbsdoc.cnd_dbs_certificate_exp_date) < 30
                      ? "text-warning"
                      : "text-danger"
                  }
                >
                  <i className="fa fa-circle-o-notch mr-1 "></i>{" "}
                  {new Date() <=
                    new Date(
                      splitDateToFormat(dbsdoc.cnd_dbs_certificate_exp_date)
                    ) && dateDiffrence(dbsdoc.cnd_dbs_certificate_exp_date) > 30
                    ? "Valid "
                    : new Date() <=
                        new Date(
                          splitDateToFormat(dbsdoc.cnd_dbs_certificate_exp_date)
                        ) &&
                      dateDiffrence(dbsdoc.cnd_dbs_certificate_exp_date) < 30
                    ? "About to Expire"
                    : "Expired"}
                </li>
              </ul>
            ))}

          {dbsdocuments.length === 0 ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : filterRows.length === 0 && inputValue ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : (
            ""
          )}

          {filterRows && filterRows.length > 10 && (
            <TableDropdownAndPagination
              handleDropDown={handleDropDown}
              recordLen={recordLen}
              handleLeft={handleLeft}
              handleRight={handleRight}
              renderPageNumbers={renderPageNumbers}
              isDropdown={true}
              isPaggination={true}
            />
          )}
          <a
            href="#"
            onClick={() => redirectUrl("/identity-document")}
            className="pull-right btn btn-primary mr-2"
          >
            Next
          </a>
        </div>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        // fileName={fileName}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={"DBS Certificate"}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dbsdocuments: state.dbsdocuments.dbsdocuments,
  };
};

export default connect(mapStateToProps)(DBSTable);
