import React from "react";
import { connect } from "react-redux";
import { Input, Textarea, Dropdown } from "../../FormElements/Forms";
const DBSNoUpperForm = (props) => {
  const { onChangeFirstFormValue, errors, dbsType, inputList } = props;
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div
              class="alert alert-warning alert-dismissible fade show clearfix"
              role="alert"
            >
              <i className="fa fa-warning text-warning" aria-hidden="true"></i>{" "}
              <strong>
                Please enter one or multiple adress(s) history of your
                residence(s). The total duration of your stay should not be less
                than 5 year's.
              </strong>
            </div>
          </div>
        </div>
      </div>
      <Input
        type="text"
        label="Title"
        changeHandler={(e) => onChangeFirstFormValue(e)}
        name="cnd_dbs_holder_title"
        id={"cnd_dbs_holder_title"}
        lg={4}
        md={4}
        value={inputList[0] && inputList[0].cnd_dbs_holder_title}
        placeholder="Eg. Mr/Mrs"
        errors={errors[0]}
      />
      <Input
        type="text"
        label="Birth Surname"
        changeHandler={(e) => onChangeFirstFormValue(e)}
        name="cnd_dbs_surname_of_birth"
        lg={4}
        md={4}
        value={inputList[0] && inputList[0].cnd_dbs_surname_of_birth}
        placeholder="Eg. Smith"
        errors={errors[0]}
      />
      <Input
        type="text"
        label="Birth Town"
        placeholder="Eg. London"
        changeHandler={(e) => onChangeFirstFormValue(e)}
        name="cnd_dbs_birth_of_town"
        lg={4}
        md={4}
        value={inputList[0] && inputList[0].cnd_dbs_birth_of_town}
        errors={errors[0]}
      />
      <Input
        type="text"
        label="Country Of Birth"
        placeholder="Eg. United Kingdom"
        changeHandler={(e) => onChangeFirstFormValue(e)}
        name="cnd_dbs_country_of_birth"
        lg={6}
        md={6}
        value={
          (inputList[0] && inputList[0].cnd_dbs_country_of_birth == "GB") ||
          inputList[0].cnd_dbs_country_of_birth == "uk"
            ? "United Kingdom"
            : inputList[0].cnd_dbs_country_of_birth
        }
        errors={errors[0]}
      />
      <Input
        type="text"
        label="NI Number"
        disabled={true}
        changeHandler={(e) => onChangeFirstFormValue(e)}
        name="cnd_dbs_nis_no"
        lg={6}
        md={6}
        value={inputList[0] && inputList[0].cnd_dbs_nis_no}
        placeholder="Eg. 123456789"
        errors={errors[0]}
      />
      
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
  };
};

export default connect(mapStateToProps)(DBSNoUpperForm);
